<template>
  <div class="set-page">
    <div class="set-wrapper">
      <div class="form-content">
        <!-- 名字 -->
        <div class="form-item" v-if="type === 'name'">
          <div class="form-text">{{setText.new}}{{communityText.name}}:</div>
          <van-field
            class="form-input"
            v-model="info.name"
            type="text"
            :maxlength="nameSize[mbLang]"
            :placeholder="setText.nameTips"
          />
        </div>
        <!-- 简介 -->
        <div class="form-item" v-if="type === 'dec'">
          <div class="form-text">{{setText.new}}{{communityText.profile}}：</div>
          <van-field
            class="des-text"
            v-model="info.doc"
            rows="5"
            autosize
            type="textarea"
            :maxlength="decSize[mbLang]"
            :placeholder="inputTips.dec"
            show-word-limit
          />
        </div>
        <!-- 按钮设置 -->
        <template  v-if="type === 'btn'">
          <div class="form-item">
            <div class="form-text">{{setText.btnName}}：</div>
            <div class="btn-text" :class="{'red-col': !info.urlText}">
              {{info.urlText ? info.urlText : setText.notSet }}
            </div>
          </div>
          <div class="form-item">
            <div class="form-text">{{setText.btnUrl}}：</div>
            <div class="btn-text" :class="{'red-col': !info.url}">
              {{info.url ? info.url : setText.notSet}}
            </div>
          </div>
          <div class="form-item">
            <div class="form-text">{{setText.btnName}}：</div>
              <van-field
            class="form-input"
            v-model="info.urlText"
            type="text"
            :maxlength="urlTextSize[mbLang]"
            :placeholder="inputTips.pleaseInput + setText.btnName"
          />
          </div>
           <div class="form-item">
            <div class="form-text">{{setText.btnUrl}}：</div>
              <van-field
            class="form-input"
            v-model="info.url"
            type="text"
            :maxlength="urlSize[mbLang]"
            :placeholder="inputTips.pleaseInput + setText.btnUrl"
          />
          </div>
        </template>
      </div>
      <VanButton class="edit-btn" @click="handleEdit">{{setText.modifyNow}}</VanButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { queryCommunityInfo, queryEditCommunity } from '@/services/community'

export default {
  name: '',
  props: ['type'],
  data () {
    return {
      info: {},
      nameSize: {
        ZH: 12,
        EN: 24
      },
      decSize: {
        ZH: 50,
        EN: 100
      },
      urlSize: 200,
      urlTextSize: {
        ZH: 6,
        EN: 12
      }
    }
  },
  computed: {
    ...mapState(['mbLang']),
    inputTips () {
      return this.$t('community.tips')
    },
    setText () {
      return this.$t('community.setText')
    },
    communityText () {
      return this.$t('community.createInfo')
    }
  },
  methods: {
    async getInfo () {
      const resp = await queryCommunityInfo()
      if (!resp.success) return
      this.info = resp.result
    },
    async handleEdit () {
      const resp = await queryEditCommunity(this.info)
      if (!resp.success) return
      this.$router.go(-1)
    }
  },
  mounted () {
    this.getInfo()
  }
}
</script>

<style scoped lang="scss">
.set-page {
  padding: 20px 13px;
  box-sizing: border-box;
}
.set-wrapper {
  color: #16172a;
  font-size: 15px;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  opacity: 1;
  border-radius: 10px;
  padding: 25px 20px;
  box-sizing: border-box;
  .form-content {
    min-height: 500px;
  }
  .form-item {
    margin-bottom: 20px;
    .form-text {
      margin-bottom: 10px;
    }
    .form-input {
      width: 100%;
      height: 50px;
      border: 1px solid #c1c1c1;
      opacity: 1;
      border-radius: 10px;
    }
    .des-text {
      border: 1px solid #c1c1c1;
      border-radius: 10px;
    }
    .btn-text {
      font-size: 12px;
      color: #A2A2A2;
    }
    .red-col {
      color: #ED8080;
    }
  }
  .edit-btn {
    width: 100%;
    height: 50px;
    background: #a85ffc;
    border-radius: 10px;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    margin-top: 20px;
  }
}
</style>
